import { AccountInfo } from "@azure/msal-browser";

export interface UserInt extends AccountInfo {
    group?: string;
    roles?: string[];
}

export enum GroupLevel {
	Admin,
	Customer,
	None
}