// interface
export interface ErrorActionInt {
	type: ErrorActionType;
	error?: any;
	props?: any;
}

// types
export enum ErrorActionType {
	HTTP_403_ERROR = 'HTTP_403_ERROR',
	HTTP_404_ERROR = 'HTTP_404_ERROR',
	HTTP_500_ERROR = 'HTTP_500_ERRROR',
	HTTP_OTHER_ERROR = 'HTTP_OTHER_ERROR',
	CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL'
}

// creators
// prop is ownProps from react-redux connect
const execute403Handler = (props: any) => ({
	type: ErrorActionType.HTTP_403_ERROR,
	props
});

const execute404Handler = (props: any) => ({
	type: ErrorActionType.HTTP_404_ERROR,
	props
});

const execute500Handler = (props: any) => ({
	type: ErrorActionType.HTTP_500_ERROR,
	props
});

const executeOtherErrorHandler = (error: any) => ({
	type: ErrorActionType.HTTP_OTHER_ERROR,
	error
});

const closeErrorModalCreator = () => ({
	type: ErrorActionType.CLOSE_ERROR_MODAL
});

// actions
export const handleHTTPError = (error: any, props: any) => (dispatch: any) => {
	switch (error.status) {
		case 403:
			props.history.push('/403');
			dispatch(execute403Handler(props));
			break;
		case 404:
			props.history.push('/404');
			dispatch(execute404Handler(props));
			break;
		case 500:
			props.history.push('/500');
			dispatch(execute500Handler(props));
			break;
		default:
			dispatch(executeOtherErrorHandler(error));
			break;
	}
};

export const closeErrorModal = () => (dispatch: any) => {
	dispatch(closeErrorModalCreator());
};