
export enum OpenStateMode {
	OPEN_STATE_MODE_SHRINK = 'shrink',
	OPEN_STATE_MODE_OVERLAP = 'overlap',
	OPEN_STATE_MODE_PUSH = 'push'
}

export enum RevealMode {
	REVEAL_MODE_SLIDE = 'slide',
	REVEAL_MODE_EXPAND = 'expand'
}

export enum Position {
	POSITION_LEFT = 'left',
	POSITION_RIGHT = 'right'
}