import { ThemeInt, ThemeMode } from '../interfaces/ThemeInt';
import { ThemeActionType } from '../actions/ThemeActions';

const defaultState: ThemeInt = {mode: ThemeMode.Dark};

interface ThemeActionInt {
	type: ThemeActionType
}

const ThemeReducer: any = (state: ThemeInt = defaultState, action: ThemeActionInt): ThemeInt => {
	Object.freeze(state);
    const newState = { ...state };

	switch (action.type) {
		case ThemeActionType.CHANGE_THEME:
			if(newState.mode == ThemeMode.Light)
				newState.mode = ThemeMode.Dark
			else
				newState.mode = ThemeMode.Light;
			return newState;

		default:
			return state;
	}
};

export default ThemeReducer;