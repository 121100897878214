import * as React from "react";
import { connect } from 'react-redux';
import { AccountInfo } from "@azure/msal-browser";

import AzureAuthenticationContext, { azureAuthenticationContext } from "./AzureAuthenticationContext";
import { loginUser, logoutUser } from '../redux/actions/UIActions';
import { StoreState } from "../redux/reducers/RootReducer";
import { Redirect } from "react-router-dom";
import { clearFleetSummary } from "../redux/actions/FleetSummaryActions";

// checks brower type
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

interface ReduxToStateProps {
	user: AccountInfo | null;
}

interface ReduxActionToProps {
	Login: Function;
	Logout: Function;
	ClearFleetSummary: Function;
}

interface AzureAuthenticationButtonProps extends ReduxToStateProps, ReduxActionToProps {}

interface State {
	isRedirect: boolean;
}

class AzureAuthenticationButton extends React.Component<AzureAuthenticationButtonProps, State> {
	authenticationModule: AzureAuthenticationContext = azureAuthenticationContext;
	state: State = {
		isRedirect: false
	};

	private login = async (method: string) => {
		const typeName = "loginPopup";
		const loginType = isIE ? "loginRedirect" : typeName;

		// Azure Login
		this.authenticationModule.login(loginType, this.returnedAccountInfo);
	};

	private logout = (): any => {
		const { user } = this.props;

		if (user) {
			this.authenticationModule.logout(user, this.props.Logout);
			this.props.ClearFleetSummary();
			this.setState({ isRedirect: true });
		}
	};

	private returnedAccountInfo = async (user: AccountInfo) => {
		const currentUser = await this.authenticationModule.getCurrentUserAccount();
		this.props.Login(currentUser);
	}

	render() {
		if (this.state.isRedirect) {
			this.setState({ isRedirect: false });
			return <Redirect to="/" />
        }

		const content = this.authenticationModule.isAuthenticationConfigured ?
							this.renderButton() :
							(<div>Authentication Client ID is not configured.</div>);
		return (
			<div id="authentication">
				{content}
			</div>
		);
	}

	private renderButton = (): JSX.Element => {
		const { user } = this.props;
		return user ? this.renderLogoutButton() : this.renderLoginButton();
	}

	private renderLoginButton = (): JSX.Element => {
		return (
			<button id="authenticationButton" className="base-button login-button" onClick={() => this.login("loginPopup")}>
				Login
			</button>
		);
	};

	private renderLogoutButton = (): JSX.Element => {
		return (
			<div id="authenticationButtonDiv">
				<div id="authentication">
					<button id="authenticationButton" className="base-button logout-button" onClick={() => this.logout()}>
						Logout
					</button>
				</div>
			</div>
		);
	};
}

const mapStateToProps = (state: StoreState) => ({
	user: state.UI.user,
	routing: state.Routing
});

const mapActionsToProps = ({
	Login: loginUser,
	Logout: logoutUser,
	ClearFleetSummary: clearFleetSummary
});

export default connect(mapStateToProps, mapActionsToProps)(AzureAuthenticationButton);