import * as React from 'react';
import AzureAuthenticationComponent from '../../azure/AzureAuthenticationComponent';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/reducers/RootReducer';
import AzureAuthenticationContext, { azureAuthenticationContext } from "../../azure/AzureAuthenticationContext";

interface ReduxStateToProps {
	Routing: RouteComponentProps;
}
interface Props extends ReduxStateToProps {}

const LandingPage = (props: Props) => {

	//Ensure handleRedirectPromise gets the chance to complete before invoking redirects.
	async function initializeMSAL(){
		await azureAuthenticationContext.handleRedirectPromise();
	}

	React.useEffect(() => {
		initializeMSAL();
	}, []);

	return (
		<div id="landing-page">
			<div className="login-form">
				<img src={`${process.env.PUBLIC_URL}/logo.png`} />
				<AzureAuthenticationComponent />
			</div>
		</div>
	);
};

const mapStateToProps = (state: StoreState, ownProps: any) => {
	return {
		Routing: state.Routing
	};
};

export default withRouter(connect(mapStateToProps)(LandingPage));