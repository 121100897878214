import React, { Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Toolbar, Item } from 'devextreme-react/toolbar';
import 'devextreme/dist/css/dx.common.css';
import './themes/dx.material.ak-dark.css';
import Chart from 'devextreme/viz/chart';

import './App.scss';
import { config } from './config';
import AzureAuthenticationContext, { azureAuthenticationContext } from './azure/AzureAuthenticationContext';
import LandingPage from './components/landingpage/LandingPage';
import Navigation from './components/shared/navigation/Navigation';
import ErrorMessage from './components/error/ErrorMessage';
import Loading from './components/shared/loading/Loading';
import AzureAuthenticationComponent from './azure/AzureAuthenticationComponent';
import { clearFleetSummary, getFleetSummary, getFleetSummaryByCustomerGroup } from './redux/actions/FleetSummaryActions';
import { handleHTTPError } from './redux/actions/ErrorHandlerActions';
import { StoreState } from './redux/reducers/RootReducer';
import { AccountInfo } from '@azure/msal-browser';
import { UIInt } from './redux/interfaces/UIInt';
import { loginUser, logoutUser } from './redux/actions/UIActions';
import CustomerContent from './templates/customer/CustomerContent';
import AdminContent from './templates/admin/AdminContent';
import { GroupLevel } from './redux/interfaces/UserInt';
import { ScrollView } from 'devextreme-react';
import moment from 'moment';
import { ThemeInt, ThemeMode } from './redux/interfaces/ThemeInt';


interface ReduxStateToProps {
	UI: UIInt;
	Routing: RouteComponentProps;
	Theme: ThemeInt;
}

interface ReduxActionsToProps {
	LoginUser: Function;
	LogoutUser: Function;
	GetFleetSummary: Function;
	GetFleetSummaryByCustomerGroup: Function;
	ClearFleetSummary: Function;
	HandleHTTPError: Function;
}

interface Props extends ReduxStateToProps, ReduxActionsToProps { }
interface State {
	opened: boolean;
	isLoading: boolean;
}

class App extends React.Component<Props, State> {
	private readonly TIMER_INTERVAL: number = 1000 * 60;	 // 1 minute
	private readonly FLEETSUMMARY_INTERVAL_TIME: number = 60000 * 3; // 3 minutes
	private readonly MAX_IDLE_HOURS: number = config.MaxIdleHours;
	private readonly auth: AzureAuthenticationContext = azureAuthenticationContext;
	private fleetInterval: any;

	state: State = {
		opened: false,
		isLoading: false,
	};

	saveFleetSummaryDateTime = () => {
		window.fleetSummaryDateTime = new Date();
	}

	setLoading(isLoading: boolean) {
		this.setState({ isLoading });
    }

	handleButtonClick = (evt: any) => {
		this.setState({ opened: !this.state.opened });
		this.resizeGraphs();
	}

	resizeGraphs = () => {
		// NOTE 10/24/2019 JRL: devextreme chart's aren't properly rerendering on size change. Charts listens to window change but not parent container. 
		// Forcing a rerender when drawer displays navbar
		const chartNodes = Array.from(document.getElementsByClassName('chart'));
		chartNodes.forEach((node: Element) => {
			const chart: any = Chart.getInstance(node);
			
			try {
				setTimeout(() => { chart.render(); }, 250);
			} catch (err) {
				console.log(err);
			}
		});
	}

	handleClose = () => {
		if (this.state.opened) {
			this.setState({ opened: false });
		}
	}

	addIdleListener = () => {
		const { TIMER_INTERVAL, MAX_IDLE_HOURS } = this;

		let maxTime = this.getMaxIdleTime();

		const timerIncrement = async () => {
			const { user } = this.props.UI;

			// debug log option
			if (window.atlasLog.idleCounter) {
				console.info(`Current time is [${moment().toString()}], auto logout at [${maxTime.toString()}]`);
            }

			if (user) {
				const curTime = moment();
				if (curTime >= maxTime) {
					// log out user from redux and cloud session via AD
					console.log(`Idle logout of user [${user.name}]`);
					await this.auth.logout(user, this.props.LogoutUser);
					this.props.ClearFleetSummary();
				}
			}
			// don't keep count if user is not signed in
			else {
				maxTime = this.getMaxIdleTime();
			}
		}

		// Add eventlisteners to document
		$(document).ready(() => {
			// Increment the idle time counter
			setInterval(timerIncrement, TIMER_INTERVAL);

			// check if there are any mouse movement or key press
			$(document).mousemove((e) => { maxTime = this.getMaxIdleTime(); });
			$(document).keypress((e) => { maxTime = this.getMaxIdleTime(); });
		});
	}

	private getMaxIdleTime() {
		const { MAX_IDLE_HOURS } = this;
		return moment().add("hours", MAX_IDLE_HOURS);
    }

	private updateFleetSummaryListener(groupLevel: GroupLevel, groupName: string = "") {
		if (this.fleetInterval !== null) {
			clearInterval(this.fleetInterval);
		}

		if (groupLevel === GroupLevel.Admin) {
			this.addFleetSummaryRefreshListener();
		} else if (groupLevel === GroupLevel.Customer) {
			this.addFleetSummaryByGroupRefreshListener(groupName);
        }
	}

	private addFleetSummaryRefreshListener = () => {
		const { FLEETSUMMARY_INTERVAL_TIME } = this;
		const { GetFleetSummary } = this.props;

		// initial call to get fleet summary
		let failedFetch = false;
		GetFleetSummary().fail((err: any) => {
			console.log(err);
			failedFetch = true;
		});
		if (!failedFetch) this.saveFleetSummaryDateTime();

		// call get fleet summary every 3 minutes
		this.fleetInterval = setInterval(() => {
			let failedFetch = false;
			GetFleetSummary().fail((err: any) => {
				console.log(err);
				failedFetch = true;
			});

			if (!failedFetch) this.saveFleetSummaryDateTime();
		}, FLEETSUMMARY_INTERVAL_TIME);
	}

	private addFleetSummaryByGroupRefreshListener = (groupName: string) => {
		if (groupName === "") return;
		const { GetFleetSummaryByCustomerGroup } = this.props;
		// initial call to get fleet summary
		let failedFetch = false;
		GetFleetSummaryByCustomerGroup(groupName)
			.fail((err: any) => {
			console.log(err);
			failedFetch = true;
		});
		if (!failedFetch) this.saveFleetSummaryDateTime();

		// call get fleet summary every 3 minutes
		this.fleetInterval = setInterval(() => {
			let failedFetch = false;
			GetFleetSummaryByCustomerGroup(groupName).fail((err: any) => {
				console.log(err);
				failedFetch = true;
			});

			if (!failedFetch) this.saveFleetSummaryDateTime();
		}, this.FLEETSUMMARY_INTERVAL_TIME);
	}

	async componentWillMount() {
		this.addIdleListener();

		// Auto Login User
		const onSuccess = (currentUser: AccountInfo | null | void): void => {
			if (currentUser) {
				this.props.LoginUser(currentUser);
			}
			this.setLoading(false);
		};

		const onError = (err: Error) => {
			console.error(err);
			this.setLoading(false);
		};

		this.setLoading(true);
		await azureAuthenticationContext.getCurrentUserAccount().then(onSuccess, onError);
	}

	componentWillReceiveProps(nextProps: Props, nextState: State) {
		if (!nextProps.UI.user) return;

		const didUserLogin = nextProps.UI.user && !this.props.UI.user;
		const isGroupSame = nextProps.UI.groupLevel === this.props.UI.groupLevel;
		const isUserSame = nextProps.UI.user &&
							this.props.UI.user &&
							nextProps.UI.user.username === this.props.UI.user.username;

		if (didUserLogin || !isGroupSame || !isUserSame) {
			this.updateFleetSummaryListener(nextProps.UI.groupLevel, nextProps.UI.user.group);
		}
	}

	render() {
		const { user } = this.props.UI;
		const { opened, isLoading } = this.state;
		const { mode } = this.props.Theme;

		if (isLoading) return <Loading />;
		if (!user) return <LandingPage />

		document.body.dataset.theme = mode;

		return (
			<div id="App">
				<Toolbar id="toolbar">
					<Item
						widget="dxButton"
						location="before"
						options={{
							icon: 'menu',
							onClick: this.handleButtonClick
						}}
					/>
					<Item location="before">
						<Link to="/">
							<img id="main-logo" src={mode === ThemeMode.Dark? "/logo.png": "/logo-light.png"} />
						</Link>
					</Item>
					<Item
						widget="dxButton"
						location="after"
					>
						<AzureAuthenticationComponent />
					</Item>
				</Toolbar>

				<Navigation opened={opened} handleClose={this.handleClose}>
					<ScrollView height="93vh">
						{this.renderContent()}
					</ScrollView>
				</Navigation>
				<ErrorMessage />
			</div>
		);
	}

	renderContent() {
		const { groupLevel } = this.props.UI;

		switch (groupLevel) {
			case GroupLevel.Admin:
				return <AdminContent/>;
			case GroupLevel.Customer:
				return <CustomerContent/>;
			case GroupLevel.None:
			default:
				return null;
		}
	} 

}

const mapStateToProps = (state: StoreState, ownProps: any) => {
	return {
		UI: state.UI,
		Routing: state.Routing,
		Theme: state.Theme
	};
};

const mapActionsToProps = {
	LoginUser: loginUser,
	LogoutUser: logoutUser,
	GetFleetSummary: getFleetSummary,
	GetFleetSummaryByCustomerGroup: getFleetSummaryByCustomerGroup,
	ClearFleetSummary: clearFleetSummary,
	HandleHTTPError: handleHTTPError
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(App));
