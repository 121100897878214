import * as FleetSummaryUtil from '../../util/FleetSummaryUtil';
import { FetchedFleetSummaryInt, SiteSummaryInt } from '../interfaces/FleetSummaryInt';

// Action Types
export enum FleetSummaryActionType {
	GET_FLEET_SUMMARY = 'GET_FLEET_SUMMARY',
	CLEAR_FLEET_SUMMARY = 'CLEAR_FLEET_SUMMARY'
}

// Action Creators
const getFleetSummaryActionCreator = (fleet: SiteSummaryInt[]) => {
	return {
		type: FleetSummaryActionType.GET_FLEET_SUMMARY,
		fleet
	};
};

const clearFleetSummaryActionCreator = () => {
	return {
		type: FleetSummaryActionType.CLEAR_FLEET_SUMMARY
    }
}

// Actions
export const getFleetSummary = () => (dispatch: any) => {
	return FleetSummaryUtil.fetchFleetsSummary().then(
		(fleet: FetchedFleetSummaryInt) => {
			const { data } = fleet;
            dispatch(getFleetSummaryActionCreator(data))
		},
	);
};

export const getFleetSummaryByCustomerGroup = (customerGroup: string) => (dispatch: any) => {
	return FleetSummaryUtil.fetchFleetSummaryByCustomerGroup(customerGroup).then(
		(fleet: SiteSummaryInt[]) => {
			dispatch(getFleetSummaryActionCreator((fleet)));
		}
	);
};

export const clearFleetSummary = () => (dispatch: any) => {
	return dispatch(clearFleetSummaryActionCreator());
};