import * as $ from 'jquery';

const baseUrl = '/api/home';

export const fetchFleetsSummary = () => {
	const url = `${baseUrl}/fleetsummary`;

	const settings: JQueryAjaxSettings = {
		url,
		method: 'GET',
	};

	return $.ajax(settings);
};

export const fetchFleetSummaryByCustomerGroup = (customerGroupName: string) => {
	const url = `${baseUrl}/fleetSummary/${customerGroupName}`;

	const settings: JQueryAjaxSettings = {
		url,
		method: 'GET'
	};

	return $.ajax(settings);
}

export const fetchSiteAlerts = (siteId: string) => {
	const url = `${baseUrl}/${siteId}/alerts`;

	const settings: JQueryAjaxSettings = {
		url,
		method: 'GET'
	};

	return $.ajax(settings);
};