import { FleetSummaryInt, SiteSummaryInt } from '../interfaces/FleetSummaryInt';
import { FleetSummaryActionType } from '../actions/FleetSummaryActions';
const defaultState: FleetSummaryInt = {};

interface Action {
	type: FleetSummaryActionType;
	fleet?: SiteSummaryInt[];
}

const FleetSummaryReducer: any = (state: FleetSummaryInt = defaultState, action: Action) => {
	Object.freeze(state);
	const newState: FleetSummaryInt = Object.assign({}, state);

	switch (action.type) {
		case FleetSummaryActionType.GET_FLEET_SUMMARY:
			if (action.fleet) {
				// sort fleet summary site ASC
				action.fleet.sort((a: SiteSummaryInt, b: SiteSummaryInt) => {
					if (a && b) {
						if (a.Name < b.Name) return -1;
						else if (a.Name > b.Name) return 1;
					}
					return 0;
				});

				// assign site summary
				action.fleet.forEach((summary: SiteSummaryInt) => {
					newState[summary.Name] = summary;
				});
			}
 
			return newState;
		case FleetSummaryActionType.CLEAR_FLEET_SUMMARY:
			return {};
		default:
			return newState;
	}
};

export default FleetSummaryReducer;