import { InteractionRequiredAuthError } from "@azure/msal-browser";
import * as $ from 'jquery';

import {
	PublicClientApplication,
	AuthenticationResult,
	AccountInfo,
	EndSessionRequest,
	RedirectRequest,
	PopupRequest
} from "@azure/msal-browser";

import { MSAL_CONFIG } from "./AzureAuthenticationConfig";
import { config } from "../config";

export class AzureAuthenticationContext {
	private account?: AccountInfo | null;
	private loginRedirectRequest?: RedirectRequest;
	private loginRequest?: PopupRequest;

	private get myMSALObj(): PublicClientApplication { return new PublicClientApplication(MSAL_CONFIG); };

	public isAuthenticationConfigured: boolean = false;

	constructor() {
		this.account = null;
		this.setRequestObjects();
		if (MSAL_CONFIG && MSAL_CONFIG.auth && MSAL_CONFIG.auth.clientId) {
			this.isAuthenticationConfigured = true;
		}
	}

	async handleRedirectPromise() {
		await this.myMSALObj.handleRedirectPromise();
    }

	login = (signInType: string, setUser: any): void => {
		if (signInType === "loginPopup") {
			this.myMSALObj
				.loginPopup(this.loginRedirectRequest)
				.then((resp: AuthenticationResult) => {
					this.handleResponse(resp, setUser);
					
				})
				.catch((err) => {
					console.error(err);
				});
		} else if (signInType === "loginRedirect") {
			this.myMSALObj.loginRedirect(this.loginRedirectRequest);
		}
	}

	logout = async (account: AccountInfo, removeUser: any) => {
		const loginClaim: string | undefined = account.idTokenClaims ? account.idTokenClaims.login_hint : undefined;
		const logoutRequest: EndSessionRequest = {
			account,
			logoutHint: loginClaim
		};

		this.myMSALObj.logoutRedirect(logoutRequest)
			.then(() => {
				this.account = null;
				removeUser(account);
			}, (reason: any) => {
				console.error(reason)
			})
			.catch((err) => { console.error(err) });
	}

	handleResponse = (response: AuthenticationResult, incomingFunction: any) => {
		if (response != null && response.account != null) {
			this.account = response.account;
		} else {
			this.account = this.getAccount();
		}

		if (this.account) {
			incomingFunction(this.account);
		}
	}

	async getToken() {
		const account = this.getAccount();
		if (!account) return;

		const accessTokenRequest = {
			scopes: config.ActiveDirectory.scopes,
			account
		};

		const accessToken = await this.myMSALObj.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
			// Acquire token silent success
			let accessToken = accessTokenResponse.accessToken;
			// Call your API with token
			return accessToken;
		}).catch((error) => {
			//Acquire token silent failure, and send an interactive request
			if (error instanceof InteractionRequiredAuthError) {
				this.myMSALObj.acquireTokenPopup(accessTokenRequest).then(function (accessTokenResponse) {
					// Acquire token interactive success
					let accessToken = accessTokenResponse.accessToken;
					// Call your API with token
					return accessToken;
				}).catch(function (error) {
					// Acquire token interactive failure
					console.error(error);
				});
			}
			console.log(error);
        });

        return accessToken;
	}

	getCurrentUserAccount = async () => {
        const account = this.getAccount();

        if (!account) return null;

        const accessTokenRequest = {
            scopes: config.ActiveDirectory.scopes,
            account
        };

		// checks if logged in
			// gets account information
        const result: any = await this.myMSALObj.acquireTokenSilent(accessTokenRequest)
            .then((resp: AuthenticationResult) => {
				// if user access is not expired
				if (resp.account) {
					this.account = resp.account;
					return this.account;
				}
				// ask to relogin user if access is expired
				else {
					const result = this.myMSALObj
						.loginPopup(this.loginRequest)
						.then((resp: AuthenticationResult) => {
							this.account = resp.account;
							return this.account
						})
						.catch((err) => {
							console.error(err);
						});
					return result;
				}
            })
            .catch((error) => {
                console.error(error);
                return null;
			});

		// set accounts user group
		result.group = await this.getCurrentUserGroup(account);
		return result;
	}

	getCurrentUserGroup = async (account: AccountInfo) => {
		const token = await this.getToken();
		if (!account) return null;

		const settings: JQueryAjaxSettings = {
			method: 'GET',
			url: `https://graph.microsoft.com/v1.0/users/${account.localAccountId}/memberOf/microsoft.graph.group`,
			beforeSend: function (xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            }			
		};

		const result = await $.ajax(settings).then((groups) => groups);
		const userGroupArr = result.value;
		const userGroup: string = userGroupArr.find((element: any) => element.displayName.includes("Group")).displayName;

		return userGroup;
    }

	private getAccount = (): AccountInfo | undefined => {
		const currentAccounts = this.myMSALObj.getAllAccounts();

		if (currentAccounts === null) {
			console.log("No accounts detected.");
			return undefined;
		}

		if (currentAccounts.length > 1) {
			console.log("Multiple accounts detected, need to add to choose account code, only selects first which is latest");
			return currentAccounts[0];
		} else if (currentAccounts.length === 1) {
			return currentAccounts[0];
		}
	}

	private setRequestObjects(): void {
		this.loginRequest = {
			scopes: config.ActiveDirectory.scopes,
			prompt: "select_account",
		};
		this.loginRedirectRequest = {
			...this.loginRequest,
			redirectStartPage: config.ActiveDirectory.redirectUri
		};
	}
}

export const azureAuthenticationContext = new AzureAuthenticationContext();
export default AzureAuthenticationContext;
