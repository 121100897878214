import * as React from 'react';
import { TreeView } from 'devextreme-react';
import { FleetSummaryInt, SiteSummaryInt, ArrayInt, FlywheelInt } from '../../../redux/interfaces/FleetSummaryInt';
import DevExpress from 'devextreme/bundles/dx.all';
import { connect } from 'react-redux';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';

interface ReduxStateToProps extends RouteComponentProps {
	FleetSummary: FleetSummaryInt;
}

interface Props extends ReduxStateToProps { }

interface State {
	isRedirected: boolean;
	path: string;
	mappedItems: any;
}

class SearchableDropdown extends React.Component<Props, State> {
	id: string = 'searchable-dropdown';
	state = {
		isRedirected: false,
		path: '',
		mappedItems: []
	}

	mapFleetToItems(fleetSummary: FleetSummaryInt): DevExpress.ui.dxTreeViewItem[] {
		const sites = Object.values(fleetSummary);
		const siteItems: DevExpress.ui.dxTreeViewItem[] = this.createSiteItems(sites);

		const itemContainer: DevExpress.ui.dxTreeViewItem = {
			text: 'Sites',
			icon: 'home',
			items: siteItems
		};

		return [itemContainer];
	}

	createSiteItems(sites: SiteSummaryInt[]): DevExpress.ui.dxTreeViewItem[] {
		const items: DevExpress.ui.dxTreeViewItem[] = [];

		sites.forEach((site: SiteSummaryInt) => {
			try {
				const item: DevExpress.ui.dxTreeViewItem = {
					text: site.Name,
					items: this.createArrayItems(site.Arrays)
				};

				items.push(item);
			} catch (ex) {
				console.error(ex)
			}
		});

		return items;
	}

	createSiteItem(site: SiteSummaryInt): DevExpress.ui.dxTreeViewItem {
		const siteItem: DevExpress.ui.dxTreeViewItem = {
			text: site.Name,
			items: this.createArrayItems(site.Arrays)
		};

		return siteItem;
	}

	createArrayItems(arrays: ArrayInt[]): DevExpress.ui.dxTreeViewItem[] {
		const items: DevExpress.ui.dxTreeViewItem[] = [];

		arrays.forEach((array: ArrayInt) => {
			const flywheelSerials = array.Flywheels.map((flywheel: FlywheelInt) => (flywheel.Serial));

			try {
				const arrayItem: DevExpress.ui.dxTreeViewItem = {
					text: array.Name,
					items: this.createFlywheelItems(flywheelSerials)
				};

				items.push(arrayItem);
			} catch (ex) {
				console.error(ex)
			}
		});

		return items;
	}

	createFlywheelItems(flywheelSerials: string[]): DevExpress.ui.dxTreeViewItem[] {
		const items: DevExpress.ui.dxTreeViewItem[] = [];

		flywheelSerials.forEach((item: string) => {
			try {
				const flywheelItem: DevExpress.ui.dxTreeViewItem = {
					text: item
				};

				items.push(flywheelItem);
			} catch (ex) {
				console.error(ex)
			}
		});

		return items;
	}

	handleItemClick = (evt: any) => {
		let url = '';
		let curNode = evt.node;

		// navigate to root node of the tree
		while (curNode) {
			const val = curNode.itemData.text;
			url = `/${val}${url}`;

			curNode = curNode.parent;
		}
		url = url === '/Sites' ? '/' : url.replace('Sites', 'Site');	// setup proper routing

		// change later, but for now don't navigate to Arrays. - JRL 11/08/2019
		// disable navigation to array pages - 12/04/2023
		const urlSegments = url.split('/');
		if (urlSegments.length == 4) { // . /Site/<SiteName>/<ArrayName>/
			return;
		}
		this.navigateTo(url);
	}

	navigateTo(url: string) {
		this.setState({
			isRedirected: true,
			path: url
		});
	}

	resetState() {
		this.setState({
			isRedirected: false,
			path: ''
		});
	}

	componentWillReceiveProps(nextProps: Props) {
		const mappedItems = this.mapFleetToItems(nextProps.FleetSummary);
		this.setState({ mappedItems });
	}

	render() {
		if (this.state.isRedirected) {
			const path = this.state.path;
			this.resetState();

			return <Redirect to={path} />;
		}

		if (this.state.mappedItems.length == 0) return null;

		return (
			<TreeView
				id={this.id}
				items={this.state.mappedItems}
				searchMode={'contains'}
				searchEnabled={true}
				onItemClick={this.handleItemClick}
			/>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		FleetSummary: state.FleetSummary,
		Routing: state.Routing
	};
};

export default withRouter(connect(mapStateToProps)(SearchableDropdown));