import * as React from 'react';
import { connect } from 'react-redux';
import { ErrorInt } from '../../redux/interfaces/ErrorHandlerInt';
import Popup, { IPopupOptions } from 'devextreme-react/popup';
import { StoreState } from '../../redux/reducers/RootReducer';
import { closeErrorModal } from '../../redux/actions/ErrorHandlerActions';

interface ReduxStateToProps {
	Error: ErrorInt;
}

interface ReduxActionToProps {
	HandleClose: any;
}

interface ErrorMessageOptions extends ReduxStateToProps, ReduxActionToProps {}

class ErrorMessage extends React.Component<ErrorMessageOptions> {

	get popupProps(): IPopupOptions {
		const { Error, HandleClose } = this.props;
		const { showErrorModal } = Error;

		return {
			className: 'error-message-popup',
			defaultVisible: showErrorModal,
			visible: showErrorModal,
			showTitle: true,
			title: 'Error',
			closeOnOutsideClick: true,
			onHiding: HandleClose,
			closeOnBackButton: true,
			showCloseButton: true,
			height: '10em'
		}
	}

	render() {

		return (
			<Popup {...this.popupProps}>
				<div className="error-message">
					<i className="dx-icon-clear" />
					<p>
						{this.props.Error.errorMessage}
					</p>
				</div>
			</Popup>
		);
	}
}

const mapStateToProps = (state: StoreState) => {
	return {
		Error: state.Error
	}
};

const mapActionToProps = {
	HandleClose: closeErrorModal
};

export default connect(mapStateToProps, mapActionToProps)(ErrorMessage);