import { ErrorActionType, ErrorActionInt } from '../actions/ErrorHandlerActions';
import { ErrorInt } from '../interfaces/ErrorHandlerInt';

const defaultState: ErrorInt = {
	showErrorModal: false,
	errorMessage: ''
};

const executeOtherError = (state: ErrorInt, action: ErrorActionInt) => ({
	...state,
	showErrorModal: true,
	errorMessage: action.error.response.data 
});

const closeErrorModal = (state: ErrorInt) => ({
	...state,
	showErrorModal: false,
	errorMessage: state.errorMessage
});

const ErrorHandlerReducer: any = (state: ErrorInt = defaultState, action: ErrorActionInt) => {
	Object.freeze(state);

	let newState = Object.assign({}, state);
	const { type } = action;

	switch (type) {
		case ErrorActionType.HTTP_OTHER_ERROR:
			return executeOtherError(newState, action);
		case ErrorActionType.HTTP_403_ERROR:
		case ErrorActionType.HTTP_404_ERROR:
		case ErrorActionType.HTTP_500_ERROR:
			return newState;
		case ErrorActionType.CLOSE_ERROR_MODAL:
			return closeErrorModal(newState);
		default:
			return newState;
	}
};

export default ErrorHandlerReducer;