import { Configuration, LogLevel } from '@azure/msal-browser';
import { config } from '../config';

const { ActiveDirectory } = config;
export const MSAL_CONFIG: Configuration = {
	auth: {
		clientId: ActiveDirectory.clientId,
		authority: ActiveDirectory.authority,
		redirectUri: ActiveDirectory.redirectUri
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) return;

				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						//console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			}
		}
	}
};