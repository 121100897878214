import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';

import RootReducer from './reducers/RootReducer';
import { createBrowserHistory } from 'history';

function configureStore(history: any, initialState: any) {

	const middleware = [
		thunk,
		//logger,
		routerMiddleware(history)
	];

	// In development, use the browser's Redux dev tools extension if installed
	const isDevelopment = process.env.NODE_ENV === 'development';
	if (isDevelopment) { middleware.push(logger); }


	return createStore(
		RootReducer,
		initialState,
		compose(applyMiddleware(...middleware))
	);
}

const initialState = {};
const history = createBrowserHistory();
const store = configureStore(history, initialState);

export default store;
