import * as React from 'react';
import { List } from 'devextreme-react';
import { Link } from 'react-router-dom';
import SearchableDropdown from '../searchable_dropdown/SearchableDropdown';
import { GroupLevel } from '../../../redux/interfaces/UserInt';
import ThemeSwitch from '../theme_switch/ThemeSwitch';

interface ListItem {
	text: string;
	icon: string;
}

interface NavigationItemsInt {
	[key: string]: ListItem
}

export enum NavigationItem {
	SeachableDropdown = 'Searchable Dropdown',
	WorldMap = 'World Map',
	FlywheelMedicalRecords = 'Flywheel Medical Records',
	Historical = 'Historical',
	Help = 'Help',
	ThemeSwitch = "Theme Switch"
}

const navigationItems: NavigationItemsInt = {
	[NavigationItem.SeachableDropdown]: {
		text: NavigationItem.SeachableDropdown,
		icon: 'home'
	},
	[NavigationItem.WorldMap]: {
		text: NavigationItem.WorldMap,
		icon: 'map'
	},
	[NavigationItem.FlywheelMedicalRecords]: {
		text: NavigationItem.FlywheelMedicalRecords,
		icon: 'doc'
	},
	[NavigationItem.Historical]: {
		text: NavigationItem.Historical,
		icon: 'event'
    },
	[NavigationItem.Help]: {
		text: NavigationItem.Help,
		icon: 'help'
	},
	[NavigationItem.ThemeSwitch]: {
		text: NavigationItem.ThemeSwitch,
		icon: 'map'
	}
}

export interface NavigationListProps {
	groupLevel: GroupLevel;
}

class NavigationList extends React.PureComponent<NavigationListProps> {
	get datasource() {
		const { groupLevel } = this.props;
		const adminNav = Object.values(NavigationItem) as NavigationItem[];
		const customerNav = [NavigationItem.SeachableDropdown, NavigationItem.WorldMap, NavigationItem.Help, NavigationItem.ThemeSwitch,];

		switch (groupLevel) {
			case GroupLevel.Admin:
				return adminNav.map((x) => navigationItems[x]);
			case GroupLevel.Customer:
				return customerNav.map((x) => navigationItems[x]);
			default:
				return [];
        }
    }

	itemRender = (item: ListItem) => {
		let path = '/';
		if (!item) return null;

		switch (item.text) {
			case NavigationItem.WorldMap:
				path += 'WorldMap'
				break;
			case NavigationItem.FlywheelMedicalRecords:
				path += 'FMR'
				break;
			case NavigationItem.SeachableDropdown:
                return <SearchableDropdown />;
			case NavigationItem.Historical:
				path += 'Historical';
				break;
			case NavigationItem.Help:
				if (this.props.groupLevel === GroupLevel.Customer) {
					path += 'Help';
					break;
                }
				
				const url = 'https://amberkinetics.atlassian.net/wiki/spaces/EN/pages/749076481/Atlas+-+How+to+use+it';
                return (
					<a target="_blank" href={url}>
                        <div className="dx-item dx-list-item" role="option">
                            <div className="dx-item-content dx-list-item-content">
                                <div className="dx-list-item-icon-container">
                                    <i className={`dx-icon dx-icon-${item.icon} dx-list-item-icon`}></i>
                                </div>
                                {item.text}
                            </div>
                        </div>
                    </a>
				);
			case NavigationItem.ThemeSwitch:
				return <ThemeSwitch />;

			default:
				break;
		}

		return (
			<Link to={path}>
				<div className="dx-item dx-list-item" role="option">
					<div className="dx-item-content dx-list-item-content">
						<div className="dx-list-item-icon-container">
							<i className={`dx-icon dx-icon-${item.icon} dx-list-item-icon`}></i>
						</div>
						{item.text}
					</div>
				</div>
			</Link>
		);
	}

	render() {
		return (
			<div className="navigation-list">
				<List
					dataSource={this.datasource}
					hoverStateEnabled={false}
					activeStateEnabled={true}
					focusStateEnabled={false}
					elementAttr={{ class: 'panel-list' }}
					itemRender={this.itemRender}
				/>
			</div>
		);
	}
}

export default NavigationList;