import * as React from 'react';
import { Drawer } from 'devextreme-react';

import { OpenStateMode, RevealMode, Position } from './NavigationOptions';
import NavigationList from './NavigationList';
import { IDrawerOptions } from 'devextreme-react/drawer';
import { StoreState } from '../../../redux/reducers/RootReducer';
import { connect } from 'react-redux';
import { UIInt } from '../../../redux/interfaces/UIInt';

interface ReduxStateToProps {
	UI: UIInt;
}

interface Props extends ReduxStateToProps {
	opened: boolean;
	handleClose: any;
	children: JSX.Element | null;
}

class Navigation extends React.PureComponent<Props> {
	private readonly openedStateMode = OpenStateMode.OPEN_STATE_MODE_OVERLAP;
	private readonly revealMode = RevealMode.REVEAL_MODE_SLIDE;
	private readonly position = Position.POSITION_LEFT; 

	get drawerProps(): IDrawerOptions {
		const { openedStateMode, revealMode, position } = this;
		const { opened, handleClose } = this.props;

		return {
			opened,
			openedStateMode,
			position,
			revealMode,
			closeOnOutsideClick: handleClose,
			height: "100vh",
			focusStateEnabled: true,
			render: () => <NavigationList groupLevel={this.props.UI.groupLevel} />
		};
    }

	render() {
		const { children } = this.props;

		return (
			<Drawer {...this.drawerProps}>
				{children}
			</Drawer>
		);
	}
}

const mapStateToProps = (state: StoreState) => {
	return {
		UI: state.UI
	};
};

export default connect(mapStateToProps)(Navigation);