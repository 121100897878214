import * as FleetSummaryUtil from '../../util/FleetSummaryUtil';
import { AlertInt } from '../interfaces/AlertInt';

// Action Types
export enum AlertActionType {
	GET_SITE_ALERTS = 'GET_SITE_ALERTS'
}

// Action Creators
const receiveAlerts = (alerts: AlertInt[]) => {
	return {
		type: AlertActionType.GET_SITE_ALERTS,
		alerts
	}
};

// Actions
export const getSiteAlerts = (siteId: string) => (dispatch: any) => {
	return FleetSummaryUtil.fetchSiteAlerts(siteId).then(
		(result: any) => {
			const { data } = result;
			dispatch(receiveAlerts(data));
		},
		(err: any) => {
			console.log(err.message);
		}
	);
};