import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router';

import BreadCrumbNav from '../../components/shared/bread_crumb/BreadCrumb'
import Loading from '../../components/shared/loading/Loading';
import { ErrorInt } from '../../redux/interfaces/ErrorHandlerInt';
import { StoreState } from '../../redux/reducers/RootReducer';
import { Suspense } from 'react';

const FleetContainer = React.lazy(() => import('../../components/fleet/FleetContainer'));
const SitePage = React.lazy(() => import('../../components/site/SitePage'));
const ArrayPage = React.lazy(() => import('../../components/array/ArrayPage'));
const FlywheelPage = React.lazy(() => import('../../components/flywheel/FlywheelPage'));
const RotorPage = React.lazy(() => import('../../components/rotor/RotorPage'));
const FMR = React.lazy(() => import('../../components/fmr/FMR'));
const WorldMap = React.lazy(() => import('../../components/worldmap/Worldmap'));
const ErrorPage = React.lazy(() => import('../../components/error/ErrorPage'));
const HistoricalPage = React.lazy(() => import('../../components/historical/HistoricalPage'));

interface ReduxStateToProps {
	Error: ErrorInt;
	Routing: RouteComponentProps;
}

interface Props extends ReduxStateToProps { }

class AdminContent extends React.PureComponent<Props> {
	render() {
		const { Error } = this.props;
		return (
			<div id={'content'} className={'dx-theme-background-color'}>
				<BreadCrumbNav />
				<Suspense fallback={<Loading />}>
					<Switch>
						<Route exact path="/" component={FleetContainer} />
						<Route path="/WorldMap" component={WorldMap} />
						<Route path="/FMR" component={FMR} />
						<Route path="/Rotors" component={RotorPage} />
						<Route exact path="/Site/:siteName" component={SitePage} />
						<Route exact path="/Site/:siteName/:arrayName" component={ArrayPage} />
						<Route exact path="/Site/:siteName/:arrayName/:flywheelSerial" component={FlywheelPage} />
						<Route exact path="/Historical" component={HistoricalPage} />
						<Route exact path="/Historical/Flywheel/:flywheelSerial" component={FlywheelPage} />
						<Route exact path="/Historical/Site/:siteName" />
						<Route path="/403" render={(props: any) => (<ErrorPage Error={Error} statusCode={403} />)} />
						<Route path="/404" render={(props: any) => (<ErrorPage Error={Error} statusCode={404} />)} />
						<Route path="/500" render={(props: any) => (<ErrorPage Error={Error} statusCode={500} />)} />
						<Route path="*" render={(props: any) => (<ErrorPage Error={Error} statusCode={404} />)} />
					</Switch>
				</Suspense>
			</div>
		);
	}
}

const mapStateToProps = (state: StoreState, ownProps: any) => ({
	Error: state.Error,
	Routing: state.Routing
});

export default withRouter(connect(mapStateToProps)(AdminContent));