import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BsMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import { ThemeInt, ThemeMode } from '../../../redux/interfaces/ThemeInt';
import { toggleTheme } from '../../../redux/actions/ThemeActions';
import { StoreState } from '../../../redux/reducers/RootReducer';
import './ThemeSwitch.scss';

interface ReduxStateToProps {
	Theme: ThemeInt;
}

interface ReduxActionsToProps {
	ToggleTheme: Function;
}

interface Props extends ReduxStateToProps, ReduxActionsToProps { }

class ThemeSwitch extends React.PureComponent<Props> {
	id: string = 'theme-switch';
	state = {
		isRedirected: false,
		path: '',
		mappedItems: []
	}

    handleToggleTheme = () => {
		const { mode } = this.props.Theme;
		document.body.dataset.theme = mode;
		this.props.ToggleTheme();
	}

	

	render() {
        const { mode } = this.props.Theme;

		return (
			<div id={this.id}>
                <input type="checkbox" className="checkbox" id="checkbox" onChange={this.handleToggleTheme} checked={mode === ThemeMode.Dark} />
                <label htmlFor="checkbox" className="label">
                    <BsMoonStarsFill />
                    <BsFillSunFill />
                    <div className="ball"></div>
                </label>
            </div>	
		);
	}
}

const mapStateToProps = (state: StoreState, ownProps: any) => {
	return {
		Theme: state.Theme
	};
};

const mapActionsToProps = {
	ToggleTheme: toggleTheme
}

export default connect(mapStateToProps, mapActionsToProps)(ThemeSwitch);