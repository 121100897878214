import { AlertInt, AlertsInt, AlertAction } from '../interfaces/AlertInt';
import { AlertActionType } from '../actions/AlertActions';

const defaultState: AlertsInt = {};

const AlertReducer: any = (state: AlertsInt = defaultState, action: AlertAction): AlertsInt => {
	Object.freeze(state);

	const newState: AlertsInt = Object.assign(state);

	switch (action.type) {
		case AlertActionType.GET_SITE_ALERTS:
			const { alerts } = action;

			alerts.forEach((alert: AlertInt) => {
				const { SiteId, EntityName } = alert;
				// populate default if values don't exist
				if (!newState[SiteId]) newState[SiteId] = {};
				if (!newState[SiteId][EntityName]) newState[SiteId][EntityName] = [];
				newState[SiteId][EntityName].push(alert);
			});
			
			return newState;
		default:
			return newState;
	}
};

export default AlertReducer;