import { config } from '../../config';
import { UIActionType } from '../actions/UIActions';
import { UIInt } from '../interfaces/UIInt';
import { GroupLevel, UserInt } from '../interfaces/UserInt';

// default state
const defaultState: UIInt = {
	user: null,
	groupLevel: GroupLevel.None
}

interface UIActionInt {
	type: UIActionType,
	user?: UserInt
}

const UIReducer: any = (state: UIInt = defaultState, action: UIActionInt) => {
	Object.freeze(state);

	const newState = { ...state };
	switch (action.type) {
		case UIActionType.LOGIN_USER:
			if (action.user) {
				newState.user = action.user;

				if (action.user.group === config.AdminGroup) {
					newState.groupLevel = GroupLevel.Admin;
				} else if (action.user.group !== config.AdminGroup) {
					newState.groupLevel = GroupLevel.Customer;
				} else if (!action.user) {
					newState.groupLevel = GroupLevel.None;
                }
			}
			return newState;
		case UIActionType.LOGOUT_USER:
			newState.user = null;
			newState.groupLevel = GroupLevel.None;
			return newState;
		default:
			return state;
	}
};

export default UIReducer;