import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Switch, Route } from 'react-router';

import BreadCrumbNav from '../../components/shared/bread_crumb/BreadCrumb'
import Loading from '../../components/shared/loading/Loading';
import { ErrorInt } from '../../redux/interfaces/ErrorHandlerInt';
import { StoreState } from '../../redux/reducers/RootReducer';
import { Suspense } from 'react';
import SitePageRedirect from '../../components/customer/site/SitePageRedirect';

const FleetNavigation = React.lazy(() => import('../../components/customer/fleet/FleetNavigation'))
const SitePage = React.lazy(() => import('../../components/customer/site/SitePage'));
const FlywheelPage = React.lazy(() => import('../../components/flywheel/FlywheelPage'));
const WorldMap = React.lazy(() => import('../../components/worldmap/Worldmap'));
const Manual = React.lazy(() => import('../../components/manual/Manual'));
const ErrorPage = React.lazy(() => import('../../components/error/ErrorPage'));

interface ReduxStateToProps {
	Error: ErrorInt;
	Routing: RouteComponentProps;
}

interface Props extends ReduxStateToProps { }

class CustomerContent extends React.PureComponent<Props> {
	render() {
		const { Error } = this.props;
		return (
			<div id={'content'} className={'dx-theme-background-color'}>
				<BreadCrumbNav />
				<Suspense fallback={<Loading />}>
					<Switch>
						<Route exact path="/" component={FleetNavigation} />
						<Route exact path="/Site" component={FleetNavigation} />
						<Route exact path="/Site/:siteName" component={FleetNavigation} />
					</Switch>

					<Switch>
						<Route exact path="/Help" component={Manual} />
						<Route exact path="/WorldMap" component={WorldMap} />
						<Route exact path="/" component={SitePageRedirect} />
						<Route exact path="/Site" component={SitePageRedirect} />
 						<Route exact path="/Site/:siteName" component={SitePage} />
						<Route exact path="/Site/:siteName/:arrayName/:flywheelSerial" component={FlywheelPage} />
						<Route path="/403" render={(props: any) => (<ErrorPage Error={Error} statusCode={403} />)} />
						<Route path="/404" render={(props: any) => (<ErrorPage Error={Error} statusCode={404} />)} />
						<Route path="/500" render={(props: any) => (<ErrorPage Error={Error} statusCode={500} />)} />
						<Route path="*" render={(props: any) => (<ErrorPage Error={Error} statusCode={404} />)} />
					</Switch>
				</Suspense>
			</div>
		);
	}
}

const mapStateToProps = (state: StoreState, ownProps: RouteComponentProps) => ({
	Error: state.Error,
	Routing: ownProps
});

export default withRouter(connect(mapStateToProps)(CustomerContent));