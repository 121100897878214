import { UserInt } from '../interfaces/UserInt';

// Action Types
export enum UIActionType {
	LOGIN_USER = "LOGIN_USER",
	LOGOUT_USER = "LOGOUT_USER"
}

// Action Creators
const loginUserActionCreator = (user: UserInt) => {
	return {
		type: UIActionType.LOGIN_USER,
		user
	};
};

const logoutUserActionCreator = () => {
	return {
		type: UIActionType.LOGOUT_USER
	};
};

// Actions
export const loginUser = (user: UserInt) => (dispatch : any) => {
	dispatch(loginUserActionCreator(user));
};

export const logoutUser = () => (dispatch: any) => {
	dispatch(logoutUserActionCreator());
};
