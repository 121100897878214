import { ThemeInt } from '../interfaces/ThemeInt';

// Action Types
export enum ThemeActionType {
	CHANGE_THEME = "CHANGE_THEME"
}

// Action Creators
const toggleThemeActionCreator = (theme: ThemeInt) => {
	return {
		type: ThemeActionType.CHANGE_THEME,
		payload: theme,
	}
};

// Actions
export const toggleTheme = (theme: ThemeInt) => (dispatch: any) => {
	dispatch(toggleThemeActionCreator(theme));
};
