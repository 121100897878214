import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';

import { StoreState } from '../../../redux/reducers/RootReducer';

interface ReduxStateToProps {
    newPath: string | null;
}

interface Props extends ReduxStateToProps { }

const SitePageRedirect: React.FunctionComponent<Props> = ({ newPath }) => {
    if (!newPath) return null;
    return <Redirect to={newPath}/>
}

const mapStatetoProps = (state: StoreState, ownProps: RouteComponentProps) => {
    const firstKey = Object.keys(state.FleetSummary)[0];
    const firstSite = state.FleetSummary[firstKey];

    let newPath: string | null = null;
    if (firstSite) {
        newPath = `Site/${firstSite.Name}`
    };

    return {
        newPath
    }
}

export default withRouter(connect(mapStatetoProps)(SitePageRedirect));