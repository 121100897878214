// reducers
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import FleetSummaryReducer from './FleetSummaryReducer';
import AlertReducer from './AlertReducer';
import UIReducer from './UIReducer';
import ErrorHandlerReducer from './ErrorHandlerReducer';
import ThemeReducer from './ThemeReducer';

// interfaces
import { FleetSummaryInt } from '../interfaces/FleetSummaryInt';
import { AlertsInt } from '../interfaces/AlertInt';
import { UIInt } from '../interfaces/UIInt';
import { ErrorInt } from '../interfaces/ErrorHandlerInt';
import { ThemeInt } from '../interfaces/ThemeInt';
import { RouteComponentProps } from 'react-router-dom';

export interface StoreState {
	FleetSummary: FleetSummaryInt;
	Alerts: AlertsInt;
	UI: UIInt;
	Error: ErrorInt;
	Routing: RouteComponentProps;
	Theme: ThemeInt;
};

const appReducer = combineReducers({
	FleetSummary: FleetSummaryReducer,
	Alerts: AlertReducer,
	Error: ErrorHandlerReducer,
	UI: UIReducer,
	Routing: routerReducer,
	Theme: ThemeReducer
});

const RootReducer = (state: any, action: any) => {
	return appReducer(state, action);
};

export default RootReducer;