export const config = {
	ActiveDirectory: {
		clientId: 'f1c32f9c-bd5e-4b8f-920a-639d43cc139f',
		authority: 'https://login.microsoftonline.com/a7de138d-d99a-4c0b-96d8-33097eacacca',
		redirectUri: window.location.origin,
		scopes: [
			'user.read.all',
			'directory.read.all',
			'profile'
		]
	},
	MaxIdleHours: 12,
	AdminGroup: 'AmberKineticsGroup',
	ManualPath: '/Manual.pdf'
};