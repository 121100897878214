import * as React from 'react';

export interface Props {
	text?: string;
	visible: boolean;
}

class LoadingSpinner extends React.PureComponent<Props> {

	render() {
		const { visible, text } = this.props;
		if (!visible) return null;
				
		return (
			<div className="loading-spinner">
				<div className="lds-roller">
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
				</div>	
				<span> { text ? text : '' } </span>
			</div>
		);
	}
}

export default LoadingSpinner;