import * as React from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

interface ReduxStateToProps extends RouteComponentProps {
	Pathname: string;
}

interface Props extends ReduxStateToProps {

}

interface Path {
	title: string;
	path: string;
}

class BreadCrumb extends React.PureComponent<Props> {

	get paths(): Path[] {
		const { Pathname } = this.props;
		const result: Path[] = [];
		if (!Pathname) return [];

		const pathArr = Pathname.split('/');
		pathArr.forEach((title: string, idx: number) => {
			const val = pathArr.slice(0, (idx + 1)).join('/');
			const path: Path = {
				title,
				path: val
			};

			result.unshift(path);
		});

		return result;
	}

	getHomepage(isActive: boolean = false) {
		const className = isActive ? 'bread-crumb active' : 'bread-crumb';

		return (
			<li className={className} key='breadcrumb-home-page'>
				<Link to={'/'}> <i className="dx-icon dx-icon-home" /> </Link>
			</li>
		);
	}

	get crumbs(): JSX.Element[] {
		const { paths } = this;
		const crumbs: JSX.Element[] = [];

		// push home page
		paths.forEach(({ title, path }, idx: number) => {
			if (path === '' || path === '/' || path === '/Site') return;

			const className = idx === 0 ? 'bread-crumb active' : 'bread-crumb';
			let crumb: JSX.Element;
			// change later, but for now don't navigate to Arrays. - JRL 11/08/2019
			// disable navigation to array pages - 12/04/2023
			const pathArr = path.split('/');

			const isFlywheel = pathArr[pathArr.length - 1].includes('Flywheel');
			const isArray = pathArr.length == 4; // . /Site/<SiteName>/<ArrayName>/
			const isKCArray = pathArr[pathArr.length - 1].includes('KingCity-C');
			if (isFlywheel || isArray || isKCArray) {
				crumb = (
					<li className={className} key={`breadcrumb-${path}`}>
						<a>{title}</a>
					</li>
				);
			} else {
				crumb = (
					<li className={className} key={`breadcrumb-${path}`}>
						<Link to={path}>{title}</Link>
					</li>
				);
			}

			crumbs.push(crumb);
		});

		const onHomePage = crumbs.length === 0;
		const homePage = onHomePage ? this.getHomepage(onHomePage) : this.getHomepage();

		crumbs.push(homePage);

		return crumbs;
	}

	render() {
		return (
			<ul id="breadcrumb-container">
				{this.crumbs}
			</ul>
		);
	}
}

const mapStateToProps = (state: any, ownProps: RouteComponentProps) => {
	const { pathname } = ownProps.location;

	return {
		Pathname: pathname,
		Routing: state.Routing
	};
};

export default withRouter(connect(mapStateToProps)(BreadCrumb));